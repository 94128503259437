<template>
  <div class="list-info">
    <top-bar :title="'活动'" :left="true"></top-bar>
    <!-- 搜索框 -->
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入活动标题" class="search-content" @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <!-- 添加活动 -->
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <!-- 选择活动 -->
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="communityShow = !communityShow" :style="{color:communityShow?'#387FF5':'#666666'}">{{community || '本街道'}}
            <img :src="require(`@/assets/img/${communityShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666'}">{{typeStr || '活动类型'}}
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">活动时间</p>
        </van-col>
<!--        <van-col span="6">-->
<!--          <p @click="beginDateShow = !beginDateShow" :style="{color:beginDateShow?'#387FF5':'#666666'}">{{selectDate.beginDate || '活动时间'}}-->
<!--            <img :src="require(`@/assets/img/${beginDateShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">-->
<!--          </p>-->
<!--        </van-col>-->
        <van-col span="6">
          <p @click="moreShow = !moreShow" :style="{color:moreShow?'#387FF5':'#666666'}">{{more || '面向对象'}}
            <img :src="require(`@/assets/img/${moreShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <!-- 点击选择项显示内容 -->
    <van-popup v-model="communityShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" value-key="label" show-toolbar :columns="communityList" @confirm="communityConfim" @cancel="communityCancel" />
    </van-popup>
    <van-popup v-model="typeShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfim" @cancel="typeCancel" />
    </van-popup>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginTimeCancel"  type="date" title="选择年月日" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="moreShow" position="bottom"  >
      <van-picker title="请选择" cancel-button-text="重置"  value-key="label" show-toolbar :columns="moreList" @confirm="moreConfim" @cancel="moreCancel"  />
    </van-popup>

    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"><span>{{selectDate.beginDate == ''?'最早':selectDate.beginDate}}</span></van-col>
        <van-col span="2" ><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"><span>{{selectDate.endDate== ''?'至今':selectDate.endDate}}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom"  >
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" :formatter="formatDate"
                           type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom"  >
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" :formatter="formatDate"
                           type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>

   <!-- 内容 -->
   <div class="cont">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="goinfo(item.id)">
            <div class="title">
              <div class="title-left" style="color:#387FF5">
                {{item.typeStr}}
              </div>
              <div class="title-right" style="color:#ED4747">
                {{item.communityStr}}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.imageMinUrl || require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{item.title}}</div>
                <div class="center-text">{{item.surveyObjectStr}}</div>
                <div class="bottom-text">{{item.activityDate}}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import topBar from '../../components/topBar/topBar.vue'
import {formatterDate} from '@/utils/utils'
import {mapMutations} from 'vuex'
import {getVirtualDict, getBelongCommunity} from '@/utils/common'
export default {
  name:'activity',
  data(){
    return{
      searchValue: '',
      page: 0,
      limit: 10,
      totalCount: 0,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      moreShow:false,
      typeShow: false,
      communityShow:false,
      loading: false,
      pullLoading: false,
      finished: false,
      more: '',
      typeStr: '',
      community:'',
      dataList: [],
      typeList: [],
      communityList:[],
      moreList:[],
      selectDate: {
        endDate: '',
        beginDate: ''
      },
      key: 1,
      data: {
        type: '',
        orgId: '',
        object: ''
      }
    }
  },
  components:{
    topBar
  },
  methods:{
    ...mapMutations,
    getDataList(){
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/list'),
        method: 'post',
        params: this.$http.adornParams({
          appOrgId: this.data.orgId,       //用户组织结构id
          page:this.page,          //当前页码
          limit:this.limit,        //每页展示的记录数
          title:this.searchValue,  //搜索条件
          surveyObject: this.data.object,
          type: this.data.type,//活动类型
          beginTime: this.selectDate.beginDate,   //活动开始时间
          endTime: this.selectDate.endDate,        //活动结束时间
          clientType: this.key == 1 ? '' : 4
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          } else {
            this.finished = false
          }
          this.dataList = this.dataList.concat(data.page.list)
          this.totalCount = data.page.totalCount
           // 加载状态结束
          this.loading = false;
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    },
    getBelongOrg () {
      getBelongCommunity(this.$orgId, -1, (communityList) => {
        this.communityList = communityList
        this.communityList.unshift({
          label: '本街道',
          key: 1,
          value: Number(this.$orgId)
        }, {
          label: '街道本级',
          key: 2,
          value: Number(this.$orgId)
        })
      })
      // this.$http({
      //   url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
      //   method: 'get',
      //   params: this.$http.adornParams({
      //     id: this.$globalData.userInfo.street,
      //     depth: -1
      //   })
      // }).then(({data})=> {
      //   if (data.code == 0) {
      //     this.communityList = data.liveWhichTree
      //   }
      // },err=> {this.$toast.error({message:'所属社区获取失败'})})
    },
    getTypeOptions () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'activityType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.typeList = data.dicts
        }
      },err=> {this.$toast.error({message:'活动类型获取失败'})})
    },
    getSubject() {
      let that = this
      getVirtualDict("activitySurveyObject", function (moreList) {
        that.moreList = moreList
      })
    },
    // 活动类型->确认
    typeConfim(value){
      this.page = 0
      this.typeStr = value.label
      this.data.type = value.value
      this.dataList = []
      this.getDataList()
      this.typeShow = false
    },
    // 所属社区 ->确认
    communityConfim(value){
      this.page = 0
      this.community = value.label
      this.data.orgId = value.value
      this.key = value.key
      this.dataList = []
      this.getDataList()
      this.communityShow = false
    },
    // 更多->确认
    moreConfim(value){
      this.page = 0
      this.more = value.label
      this.data.object = value.value
      this.dataList = []
      this.getDataList()
      this.moreShow = false
    },
    // 清空搜索框
    onCancel(){
      this.page = 0
      this.dataList = []
      this.searchValue = '',
      this.getDataList()
    },
    // 搜索
    onSearch(){
      this.page = 0
      this.dataList = []
      this.getDataList()

    },
    // 活动类型选择
    typeCancel(){
       this.page = 0
      this.dataList = []
      this.typeStr = ''
      this.data.type = ''
      this.typeShow= false
      this.getDataList()
    },
    formatDate (type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      return val
    },
    // 活动时间
    beginDateConfim (value) {
      let d = new Date(this.selectDate.endDate)
      if (this.selectDate.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.selectDate.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    endDateConfim (value) {
      let d = new Date(this.selectDate.beginDate)
      if (this.selectDate.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.selectDate.endDate = formatterDate(value)
      this.endDateShow = false
    },
    changeDate () {
      this.dataList = []
      this.page = 0
      this.finished = false
      this.getDataList()
    },
    dateClose () {
      this.selectDate = {
        endDate: '',
        beginDate: ''
      }
    },
    beginTimeCancel() {
      this.page = 0
      this.dataList = []
      this.selectDate.beginDate = ''
      this.beginDateShow= false
      this.getDataList()
    },
    endTimeCancel() {
      this.page = 0
      this.dataList = []
      this.selectDate.endDate = ''
      this.endDateShow= false
      this.getDataList()
    },
    // 更多 ->重置
    moreCancel(){
       this.page = 0
      this.limit = 10
      this.dataList = []
      this.more = ''
      this.data.object = ''
      this.moreShow= false
      this.getDataList()
    },
    // 所属社区->重置
    communityCancel(){
       this.page = 0
      this.dataList = []
      this.community = ''
      this.data.orgId = ''
      this.communityShow= false
      this.getDataList()
    },
    // 下拉刷新
    onPullDownRefresh(e){
      this.finished =false
      this.dataList = []
      this.page = 0;
      this.getDataList()

    },
    // 添加活动
    goAdd(){
      this.$router.push({path:'/activity-add'})
    },
    // 活动详情
    goinfo(id){
      this.$store.commit('setActivityId',id)
      this.$router.push({path:'/activity-info',query:{id}})
    }

  },
  created (){
    this.data.orgId = this.$orgId
    this.getBelongOrg()
    this.getTypeOptions()
    this.getSubject()
    this.getDataList()
  }
}
</script>

<style scoped lang="scss">
.center-text {
  color: #666;
  font-size: 0.34667rem;
  margin-top: 0.2rem;
  width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top-text {
  font-weight: bold;
}
.bottom-text {
  margin-top: 0.2rem !important;
}
.list-item .content {
  height: 2rem !important;
}
</style>
